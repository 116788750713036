import * as React from 'react';

const NotFoundPage = () => {
  const browser = typeof window !== 'undefined' && window;

  return (
    <>
      {browser && (
        <>
          <h1>Page not found</h1>
        </>
      )}
    </>
  );
};

export default NotFoundPage;
